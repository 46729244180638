import {Outlet } from "react-router-dom";

function DatabaseView(){
    return (
        <>
            <Outlet />
        </>
    );
}

export default DatabaseView